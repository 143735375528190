/* Footer */

.footer-container {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 0.75rem;
    padding-right: 1rem;
    z-index: 1;
}

.footer-text {
    transition: color 0.5s linear;
    color: var(--tertiaryTextColor);
}

.footer-link {
    transition: color 0.5s linear;
    text-decoration: none;
    color: var(--secondaryTextColor);
}
